module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Accompagnement technique pour les startups, TPE et PME","description":"Concentrez-vous sur votre métier et laissez un expert vous fournir les outils nécessaires pour réussir.","short_name":"David Dérus","lang":"fr","start_url":"/","background_color":"#ffffff","theme_color":"#0b1f3a","display":"standalone","icon":"static/img/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"278bfe2446984fde9617e39b2be9199f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://analytics.davidderus.fr","siteUrl":"https://davidderus.fr","matomoPhpScript":"matomo.php","matomoJsScript":"matomo.js","disableCookies":true,"requireConsent":false},
    }]

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-index-js": () => import("./../../../src/templates/about-page/index.js" /* webpackChunkName: "component---src-templates-about-page-index-js" */),
  "component---src-templates-category-page-index-js": () => import("./../../../src/templates/category-page/index.js" /* webpackChunkName: "component---src-templates-category-page-index-js" */),
  "component---src-templates-contact-page-index-js": () => import("./../../../src/templates/contact-page/index.js" /* webpackChunkName: "component---src-templates-contact-page-index-js" */),
  "component---src-templates-default-page-index-js": () => import("./../../../src/templates/default-page/index.js" /* webpackChunkName: "component---src-templates-default-page-index-js" */),
  "component---src-templates-home-page-index-js": () => import("./../../../src/templates/home-page/index.js" /* webpackChunkName: "component---src-templates-home-page-index-js" */),
  "component---src-templates-service-page-index-js": () => import("./../../../src/templates/service-page/index.js" /* webpackChunkName: "component---src-templates-service-page-index-js" */)
}

